/*global $*/
/*eslint no-undef: "error"*/

export default class Phone {
    classBlock = 'phone--block'
    classBlockOpen = 'phone-block-open'
    classButton = 'phone--button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classBlock}`).not($(this).closest(`.${self.classBlock}`)).removeClass(self.classBlockOpen)
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
