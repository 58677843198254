/*global $*/
/*eslint no-undef: "error"*/

export default class ButtonMore {
    classBlock = 'button-more--block'
    classButton = 'button-more--button'
    classHidden = 'button-more-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).find(`.${self.classHidden}`).removeClass(self.classHidden)
            $(this).addClass(self.classHidden)
        })
    }
}
