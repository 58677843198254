/*global $*/
/*eslint no-undef: "error"*/

export default class MenuToggle {
    classBlock = 'menu-toggle--block'
    classBlockOpen = 'header-mobile-open'
    classButton = 'menu-toggle--button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
