/*global $*/
/*eslint no-undef: "error"*/

export default class WidgetView {
    classBlock = 'widget-view--block'
    classBlockHidden = 'page-hidden'
    classButton = 'widget-view--button'
    classKey = 'widget-type--fields'

    constructor () {
        let self = this

        if (localStorage.getItem(self.classKey) !== null) {
            let objFields = JSON.parse(localStorage.getItem(self.classKey))

            if ((parseInt(objFields.size) > 1) || (parseInt(objFields.color) > 1) || (parseInt(objFields.img) > 1)) {
                $(`.${self.classBlock}`).removeClass(self.classBlockHidden)
            }
        }

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classBlock}`).toggleClass(self.classBlockHidden)
        })
    }
}
