/*global $*/
/*eslint no-undef: "error"*/

export default class WidgetType {
    classBlock = 'widget-type--block'
    classButton = 'widget-type--button'
    classButtonActive = 'active'
    classKey = 'widget-type--fields'

    constructor () {
        let self = this

        if (localStorage.getItem(self.classKey) !== null) {
            let objHtml = $(`html`)
            let objFields = JSON.parse(localStorage.getItem(self.classKey))

            // size
            $(`.${self.classButton}[data-type='size']`).removeClass(self.classButtonActive)
            $(`.${self.classButton}[data-type='size'][data-number='${objFields.size}']`).addClass(self.classButtonActive)
            objHtml.addClass(`-size--${objFields.size}`)

            // color
            $(`.${self.classButton}[data-type='color']`).removeClass(self.classButtonActive)
            $(`.${self.classButton}[data-type='color'][data-number='${objFields.color}']`).addClass(self.classButtonActive)
            objHtml.addClass(`-color--${objFields.color}`)

            // img
            $(`.${self.classButton}[data-type='img']`).removeClass(self.classButtonActive)
            $(`.${self.classButton}[data-type='img'][data-number='${objFields.img}']`).addClass(self.classButtonActive)
            objHtml.addClass(`-img--${objFields.img}`)
        }

        $(`.${self.classButton}`).click(function () {
            if (!$(this).hasClass(self.classButtonActive)) {
                let objHtml = $(`html`)
                let type = $(this).data('type')
                let number = $(this).data('number')
                let regexSize = /(\s)*(-size--.*?)(?=\s)/g
                let regexColor = /(\s)*(-color--.*?)(?=\s)/g
                let regexImg = /(\s)*(-img--.*?)(?=\s)/g
                let objFields

                $(this).closest(`.${self.classBlock}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
                $(this).addClass(self.classButtonActive)

                objHtml.addClass(`-${type}--${number}`)

                if (localStorage.getItem(self.classKey) === null) {
                    objFields = {
                        size: 1,
                        color: 1,
                        img: 1
                    }

                    localStorage.setItem(self.classKey, JSON.stringify(objFields))
                } else {
                    objFields = JSON.parse(localStorage.getItem(self.classKey))
                }

                if (type === 'size') {
                    objHtml[0].className = objHtml[0].className.replace(regexSize, '')
                    objFields.size = number
                }

                if (type === 'color') {
                    objHtml[0].className = objHtml[0].className.replace(regexColor, '')
                    objFields.color = number
                }

                if (type === 'img') {
                    objHtml[0].className = objHtml[0].className.replace(regexImg, '')
                    objFields.img = number
                }

                localStorage.setItem(self.classKey, JSON.stringify(objFields))
            }
        })
    }
}
