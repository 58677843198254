/*global $*/
/*eslint no-undef: "error"*/

export default class Map {
    classBlock = 'map--block'
    classButton = 'map--button'
    classTransparent = 'button-transparent'
    classItem = 'map--item'
    classItemActive = 'map__block-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classBlock}`).find(`.${self.classButton}`).addClass(self.classTransparent)
            $(this).removeClass(self.classTransparent)

            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}`).removeClass(self.classItemActive)
            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}[data-block='${block}']`).addClass(self.classItemActive)
        })
    }
}
