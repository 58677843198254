<template lang="pug">
.form
  .form__block(:class="{'form__block-hidden': sendForm}")
    .form__fields
      .form__field
        label.input-text
          input.input-text__input(
            type="text"
            :placeholder="formFieldNameLabel"
            v-model="fieldName"
          )

      .form__field
        label.input-text
          input.input-text__input(
            type="text"
            :placeholder="formFieldTemaLabel"
            v-model="fieldTema"
          )

      .form__field
        label.input-text
          input.input-text__input(
            type="email"
            :placeholder="formFieldEmailLabel"
            v-model="fieldEmail"
          )

      .form__field
        label.input-text
          input.input-text__input(
            type="tel"
            :placeholder="formFieldPhoneLabel"
            v-model="fieldPhone"
            v-maska="['+7 (###) ###-####', '+7 (###) ###-####']"
          )

      .form__field
        label.input-textarea
          textarea.input-textarea__input(
            :placeholder="formFieldMessageLabel"
            v-model="fieldMessage"
          )

      .form__field
        .form-files
          .form-files__list
            .form-files__item
              input(
                  type="file"
                  name="files"
                  ref="files"
                  id="files"
                  multiple
                  style="display: none;"
                  @change="onFileChange"
              )
              label.form-files__label(
                  v-html="formFieldFilesButton"
                  for="files"
              )
            .form-files__item
              label.form-files__result(v-html="fileResult")
          .form-files__description(v-html="formFieldFilesText")

      .form__field
        label.input-checkbox
            input.input-checkbox__input(
                type="checkbox"
                v-model="consent"
                value="consent"
                checked="checked"
            )
            span.input-checkbox__label(v-html="formConsent")

      .form__field
        .button(
            :class="{'button-disable': v$.$invalid}"
            @click="getSend()"
        )
            .button__name(v-html="formButtonName")

  .form__block(:class="{'form__block-hidden': !sendForm}")
    .form-success
      h2.center(v-html="formSuccessTitle")
      p.center(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
    name: 'AppForm',

    setup () {
        return { v$: useVuelidate() }
    },

    props: [
        'formSettingTitle',
        'formSettingSubject',
        'formButtonName',

        'formFieldNameLabel',
        'formFieldName',

        'formFieldTemaLabel',
        'formFieldTema',

        'formFieldEmailLabel',
        'formFieldEmail',

        'formFieldPhoneLabel',
        'formFieldPhone',

        'formFieldMessageLabel',
        'formFieldMessage',

        'formFieldFilesButton',
        'formFieldFilesResult',
        'formFieldFilesText',
        'formFieldFilesReq',

        'formSuccessTitle',
        'formSuccessText',

        'formConsent'
    ],

    data () {
        return {
            fieldName: '',
            fieldTema: '',
            fieldEmail: '',
            fieldPhone: '',
            fieldMessage: '',
            consent: ['consent'],
            sendForm: false,

            files: '',
            fileResult: this.formFieldFilesResult
        }
    },

    methods: {
        ...mapActions([
            'Send'
        ]),

        onFileChange () {
            this.files = this.$refs.files.files

            if (this.files.length === 1) {
                this.fileResult = 'Добавлен ' + this.files.length + ' файл'
            } else if ((this.files.length === 2) || (this.files.length === 3) || (this.files.length === 4)) {
                this.fileResult = 'Добавлено ' + this.files.length + ' файла'
            } else {
                this.fileResult = 'Добавлено ' + this.files.length + ' файлов'
            }
        },

        getSend () {
            if (!this.v$.$invalid) {
                this.sendForm = !this.sendForm

                this.Send({
                    setting: {
                        title: this.formSettingTitle,
                        subject: this.formSettingSubject
                    },

                    fields: [
                        {
                            title: this.formFieldName,
                            value: this.fieldName
                        },
                        {
                            title: this.formFieldTema,
                            value: this.fieldTema
                        },
                        {
                            title: this.formFieldEmail,
                            value: this.fieldEmail
                        },
                        {
                            title: this.formFieldPhone,
                            value: this.fieldPhone
                        },
                        {
                            title: this.formFieldMessage,
                            value: this.fieldMessage
                        }
                    ],
                    files: this.files
                })

                this.fieldName = this.fieldTema = this.fieldEmail = this.fieldPhone = this.fieldMessage = this.files = ''

                setTimeout(() => {
                    this.sendForm = !this.sendForm
                }, 5000)
            }
        }
    },

    validations () {
        return {
            fieldName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(150)
            },
            fieldTema: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(150)
            },
            fieldMessage: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(600)
            },
            fieldEmail: {
                required,
                email
            },
            files: {
              validator: () => {
                if (this.formFieldFilesReq === 'req') {
                  return this.files.length
                } else {
                  return true
                }
              }
            },
            consent: {
                required
            }
        }
    }
}
</script>
