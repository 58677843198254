/*global $*/
/*eslint no-undef: "error"*/
export default class MenuMobile {
    classBlock = 'menu-mobile--block'
    classBlockOpen = 'menu-mobile__item-open'
    classButton = 'menu-mobile--button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
