/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import ButtonMore from '@/assets/js/Button/More/ButtonMore'
import Map from '@/assets/js/Map/Map'
import MapIframe from '@/assets/js/Map/Iframe/MapIframe'
import MenuToggle from '@/assets/js/Menu/Toggle/MenuToggle'
import Phone from '@/assets/js/Phone/Phone'
import WidgetView from '@/assets/js/Widget/View/WidgetView'
import WidgetType from '@/assets/js/Widget/Type/WidgetType'
import MenuMobile from '@/assets/js/Menu/Mobile/MenuMobile'
import Faq from '@/assets/js/Faq/Faq'
import AppForm from '@/components/Form/AppForm.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')

    if(document.getElementById('block--form')!= null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form')
    }

    new SwiperSlider()
    new ButtonMore()
    new Map()
    new MapIframe()
    new MenuToggle()
    new Phone()
    new WidgetView()
    new WidgetType()
    new MenuMobile()
    new Faq()
})
