import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs, Lazy} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs, Lazy])

export default class SwiperSlider {

    createSlider () {
        return new Swiper('#slider--slider', {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--slider',
                clickable: true
            },
            breakpoints: {}
        })
    }

    constructor () {
        if(document.getElementById('slider--slider')!= null) {
            this.createSlider()
        }
    }
}
